import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Title from "../components/Title"
import Image from "gatsby-image"
import SEO from "../components/SEO"
import { Link } from "gatsby"
import {
  SiGatsby,
  SiStrapi,
  SiMongodb,
  SiExpress,
  SiRedux,
  SiTypescript,
  SiNextdotjs,
} from "react-icons/si"
import {
  FaHtml5,
  FaCss3Alt,
  FaSass,
  FaJsSquare,
  FaReact,
  FaNodeJs,
  FaGitSquare,
} from "react-icons/fa"

const About = ({
  data: {
    about: { nodes },
  },
}) => {
  const { title, info, stack, image } = nodes[0]

  return (
    <Layout>
      <SEO
        title="About"
        description="Hi there, I'm Bibo, a self-taught web developer. This is my portfolio site. 這裡也會分享我從文組轉職工程師的心路歷程 : )"
      />
      <section className="about-page">
        <div className="section-center about-center">
          <Image
            fluid={image.localFile.childImageSharp.fluid}
            className="about-img"
          />
          <article className="about-text">
            <Title title={title} />
            <p>{info}</p>
            <p>
              <Link to="/blogs/how-i-started-coding">
                {" "}
                &raquo; 更多關於我轉職自學的心路歷程，請見這篇文章
              </Link>
            </p>
            <div className="about-stack">
              {stack.map(item => {
                return <span key={item.id}>{item.title}</span>
              })}
            </div>
            <div>
              <FaHtml5 className="about-icon" />
              <FaCss3Alt className="about-icon" />
              <FaJsSquare className="about-icon" />
              <FaSass className="about-icon" />
              <FaReact className="about-icon" />
              <SiRedux className="about-icon" />
              <FaNodeJs className="about-icon" />
              <SiMongodb className="about-icon" />
              <SiExpress className="about-icon" />
              <FaGitSquare className="about-icon" />
              <SiGatsby className="about-icon" />
              <SiStrapi className="about-icon" />
              <SiTypescript className="about-icon" />
              <SiNextdotjs className="about-icon" />
            </div>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    about: allStrapiAbout {
      nodes {
        stack {
          id
          title
        }
        title
        info
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default About
